//SliderSlick

//Appel autres fichiers
/*var $ = require('jquery');
window.$ = $;

require('./slick.min.js');

$.noConflict();*/

$('.main_slider.listCat').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 300,
        centerPadding: '60px',
        pauseOnHover: true,
        pauseOnFocus: true,
        dots: false,
        rows: 0,
        prevArrow: $(".prev-arrow.listcat"),
        nextArrow: $(".next-arrow.listcat"),
        asNavFor: '.slider_dots',
        lazyLoad: 'ondemand',
    })
    .on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
$('.slider_dots.listcat').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.main_slider',
    arrows: false,
    dots: false,
    centerMode: true,
    infinite: true,
    focusOnSelect: true,
    centerPadding: '63px',
});
